import { resourceContentBackup } from '../../../eventCenter/analysis/utils'

const { SiteUID, IS_RW } = gbCommonInfo
import { stringifyQueryString, parseQueryString } from '@shein/common-function'
export class WebPush {
  static name = 'WebPush'

  #owner = IS_RW ? '55d9d308f923' : 'c06db2f5231a'
  #siteMap = !IS_RW
    // SH
    ? {
      www: {
        domain: 'www.shein.com',
        idSite: '13774',
        webpushid: 'web.76.aimtell.com'
      },
      it: {
        domain: 'it.shein.com',
        idSite: '14013',
        webpushid: 'web.79.aimtell.com'
      },
      us: {
        domain: 'shein.clbug.com',
        idSite: '14068',
        webpushid: 'web.92.aimtell.com'
      },
      fr: {
        domain: 'fr.shein.com',
        idSite: '14069',
        webpushid: 'web.30.aimtell.com'
      },
      uk: {
        domain: 'www.shein.co.uk',
        idSite: '14071',
        webpushid: 'web.94.aimtell.com'
      },
      de: {
        domain: 'de.shein.com',
        idSite: '14072',
        webpushid: 'web.95.aimtell.com'
      },
      es: {
        domain: 'es.shein.com',
        idSite: '14073',
        webpushid: 'web.29.aimtell.com'
      },
      in: {
        domain: 'www.shein.in',
        idSite: '14209',
        webpushid: 'web.28.aimtell.com'
      },
      nl: {
        domain: 'nl.shein.com',
        idSite: '14215',
        webpushid: 'web.98.aimtell.com'
      },
      mx: {
        domain: 'www.shein.com.mx',
        idSite: '14216',
        webpushid: 'web.99.aimtell.com'
      },
      tw: {
        domain: 'www.shein.tw',
        idSite: '14217',
        webpushid: 'web.100.aimtell.com'
      },
      ar: {
        domain: 'ar.shein.com',
        idSite: '14219',
        webpushid: 'web.50.aimtell.com'
      },
      au: {
        domain: 'au.shein.com',
        idSite: '14220',
        webpushid: 'web.68.aimtell.com'
      },
      ca: {
        domain: 'ca.shein.com',
        idSite: '14221',
        webpushid: 'web.67.aimtell.com'
      },
      th: {
        domain: 'th.shein.com',
        idSite: '14222',
        webpushid: 'web.69.aimtell.com'
      },
      il: {
        domain: 'il.shein.com',
        idSite: '14223',
        webpushid: 'web.66.aimtell.com'
      },
      eur: {
        domain: 'eur.shein.com',
        idSite: '14224',
        webpushid: 'web.70.aimtell.com'
      },
      vn: {
        domain: 'www.shein.com.vn',
        idSite: '14225',
        webpushid: 'web.65.aimtell.com'
      },
      hk: {
        domain: 'shein.clbug.com',
        idSite: '14226',
        webpushid: 'web.71.aimtell.com'
      },
      se: {
        domain: 'www.shein.se',
        idSite: '14227',
        webpushid: 'web.64.aimtell.com'
      },
      nz: {
        domain: 'nz.shein.com',
        idSite: '19690',
        webpushid: 'web.82.aimtell.com'
      },
      ma: {
        domain: 'ma.shein.com',
        idSite: '19691',
        webpushid: 'web.38.aimtell.com'
      },
      za: {
        domain: 'za.shein.com',
        idSite: '19692',
        webpushid: 'web.83.aimtell.com'
      },
      sg: {
        domain: 'sg.shein.com',
        idSite: '19693',
        webpushid: 'web.37.aimtell.com'
      },
      br: {
        domain: 'br.shein.com',
        idSite: '19694',
        webpushid: 'web.84.aimtell.com'
      },
      cl: {
        domain: 'cl.shein.com',
        idSite: '19695',
        webpushid: 'web.36.aimtell.com'
      },
      pl: {
        domain: 'pl.shein.com/',
        idSite: '19900',
        webpushid: 'web.27.aimtell.com'
      },
      asia: {
        domain: 'asia.shein.com/',
        idSite: '20573',
        webpushid: 'web.52.aimtell.com'
      },
      ph: {
        domain: 'ph.shein.com/',
        idSite: '22511',
        webpushid: 'web.27.aimtell.com'
      },
      pt: {
        domain: 'pt.shein.com/',
        idSite: '23788',
        webpushid: 'web.2.aimtell.com'
      },
      ch: {
        domain: 'ch.shein.com/',
        idSite: '24477',
        webpushid: 'web.2.aimtell.com'
      },
      my: {
        domain: 'my.shein.com/',
        idSite: '24700',
        webpushid: 'web.2.aimtell.com'
      },
      jp: {
        domain: 'jp.shein.com/',
        idSite: '24887',
        webpushid: 'web.2.aimtell.com'
      },
      euqs: {
        domain: 'euqs.shein.com/',
        idSite: '27693',
        webpushid: 'web.2.aimtell.com'
      },
      kr: {
        domain: 'kr.shein.com/',
        idSite: '29476',
        webpushid: 'web.2.aimtell.com'
      }
    }
    // RW
    : {
      rwus: {
        domain: 'us.romwe.com',
        idSite: '16492',
        webpushid: 'web.66.aimtell.com'
      },
      rwfr: {
        domain: 'fr.romwe.com',
        idSite: '16493',
        webpushid: 'web.70.aimtell.com'
      },
      rwuk: {
        domain: 'uk.romwe.com',
        idSite: '21471',
        webpushid: 'web.87.aimtell.com'
      },
      rwca: {
        domain: 'ca.romwe.com',
        idSite: '21472',
        webpushid: 'web.88.aimtell.com'
      },
    }

  constructor() {
    const account = this.#siteMap[SiteUID]
    this.active = false
    if (!this.active) return

    const _at = {
      ...account,
      owner: this.#owner,
      attributes: {},
      track() {
        _at.track.q.push(arguments)
      },
    }
    _at.track.q = []
    window._at = _at
  }

  async init() {
    const isClose = false
    if(isClose)return
    this._landing()

    return [{
      TPM_Ele_Type: 'script',
      src: '//s3.amazonaws.com/cdn.aimtell.com/trackpush/trackpush.min.js'
    }]
  }

  _landing() {
    if (!window.history?.pushState) return

    try {
      const query = parseQueryString(location.search) || {}
      if (query?.utm_source !== 'webpush') return
      const webPushIci = resourceContentBackup()
      if (!webPushIci) return

      history.replaceState(null, null, `${location.origin}${location.pathname}?${stringifyQueryString({ queryObj: query })}`)
    } catch (error) {
      console.error(error)
    }
  }
}
